import Swal from 'sweetalert2/dist/sweetalert2';

import { createRouter, createWebHistory } from 'vue-router';
import useGlobalFunctions from '@/composables/global_functions.js';
import authRoutes from './auth';
import cdsRoutes from './cds';
import otherRoutes from './other';

const { isAuthorized, checkSubscriptionRedirection } = useGlobalFunctions();
import { useAuthStore } from '@/stores/auth';
import { template } from 'lodash';

    
const routes = [
    ...authRoutes,
    ...cdsRoutes,
    ...otherRoutes,
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass : 'active'
})

router.beforeEach((to, from) => {

    if(localStorage.getItem("authDisplay")){
        // Kitchen Authentication
        if ( !_.isUndefined(to.meta) && to.meta.authRequired && !isAuthorized(to.meta.key)) {
            return { name: '401' }
        }
    }
    
})

export default router;


