const cdsRoutes = [
    {
        path : '/cds',
        component : () => import('@/pages/restaurant/cds.vue'),
        name : 'CDS Screen',
        meta : {
            key : 'SM_CDS',
            authRequired : true,
        },
    },
    
];

export default cdsRoutes;